import {getContactHref, getIcon} from '../../../utils';

import Icon from '../../Icon';
// @flow strict
import React from 'react';
import moment from 'moment';
import styles from './Content.module.scss';

type Props = {
  body: string,
  date: string,
  title: string,
  hero: string,
  slug: string,
};

const Content = ({body, date, title, hero, slug}: Props) => (
  <div className="">
    <img src={hero} />
    <time className="flex justify-center mt-6 text-base" dateTime={moment(date).format('YYYY/MM/DD')}>
      {moment(date).format('YYYY/MM/DD')}
    </time>
    <h1 className="text-3xl font-bold text-center mt-6 mb-14 w-full md:w-4/6 mx-auto">{title}</h1>
    <div className={`${styles['content__body']} px-4 md:px-12`} dangerouslySetInnerHTML={{__html: body}} />
    <div className="mb-3 px-4 md:px-12 text-blue-400">
      <a
        href={`https://twitter.com/intent/tweet?url=https://en.taishikato.com/posts/${slug}&text=${title}`}
        target="_blank"
        rel="noopener">
        <Icon name="Twiter" icon={getIcon('twitter')} />
      </a>
    </div>
  </div>
);

export default Content;
