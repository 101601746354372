import {Link} from 'gatsby';
import React from 'react';
import {ThemeToggler} from 'gatsby-plugin-dark-mode';
import styles from './DarkButton.module.scss';

const Title = () => (
  <div className="flex justify-between items-center px-3">
    <h1 className="flex justify-center my-5">
      <Link to="/" className="font-extrabold text-2xl">
        Taishi Kato
      </Link>
    </h1>
    <ThemeToggler>
      {({theme, toggleTheme}) => (
        <div className={`${styles['dark-button']} dark-button w-14 h-8`}>
          <input
            type="checkbox"
            id="toggle"
            className="hidden"
            onChange={e => toggleTheme(e.target.checked ? 'dark' : 'light')}
            checked={theme === 'dark'}
          />
          <label htmlFor="toggle"></label>
        </div>
      )}
    </ThemeToggler>
  </div>
);

export default Title;
