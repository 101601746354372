import {Link} from 'gatsby';
// @flow strict
import React from 'react';

type Props = {
  tags: string[],
  tagSlugs: string[],
};

const Tags = ({tags, tagSlugs}: Props) => (
  <div>
    <ul>
      {tagSlugs &&
        tagSlugs.map((slug, i) => (
          <li className="mt-3">
            <Link to={slug} className="rounded-full border border-white px-3 py-2 inline-block font-semibold">
              {tags[i]}
            </Link>
          </li>
        ))}
    </ul>
  </div>
);

export default Tags;
