import Author from './Author';
import Comments from './Comments';
import Content from './Content';
import {Link} from 'gatsby';
import Meta from './Meta';
import type {Node} from '../../types';
import React from 'react';
// @flow strict
import Tags from './Tags';
import Title from '../Title/Title';
import styles from './Post.module.scss';

type Props = {
  post: Node,
};

const Post = ({post}: Props) => {
  const {html} = post;
  const {tagSlugs, slug} = post.fields;
  const {tags, title, date, hero} = post.frontmatter;

  return (
    <div className={styles['post']}>
      <div className="w-full md:w-9/12 mx-auto">
        <Title />
      </div>
      <div className="bg-white dark:bg-gray-800 w-full md:w-9/12 mx-auto pb-10 mb-10">
        <div className={styles['post__content']}>
          <Content slug={slug} body={html} title={title} hero={hero} date={date} />
        </div>

        <div className={styles['post__footer']}>
          <Meta date={date} />
          {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
          <Author />
        </div>

        <div className={styles['post__comments']}>
          <Comments postSlug={slug} postTitle={post.frontmatter.title} />
        </div>
      </div>
    </div>
  );
};

export default Post;
